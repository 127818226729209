/** @jsx jsx */
import { Col, Container, Row } from 'react-bootstrap'
import { jsx } from '@emotion/react'
import ArrowUp from '../assets/images/lp/home/arrow-w.svg'
import estrutura1 from '../assets/images/lp/estrutura/estrutura3.png'
import estrutura2 from '../assets/images/lp/estrutura/estrutura2.png'
import estrutura3 from '../assets/images/lp/estrutura/estrutura1.png'

import {
  EstruturaHomeContent,
  headerContent,
  pdR,
} from '../assets/styles/EstruturaHome.styles'

const EstruturaHome = () => {
  return (
    <section css={EstruturaHomeContent}>
      <Container>
        <Row
          data-aos-offset='300'
          data-aos='fade-down'
          data-aos-easing='ease-in-out'
          css={headerContent}
        >
          <Col lg={12} md={12} sm={12}>
            <h2>Estrutura</h2>
            <p>
              Descubra nossa estrutura acolhedora e inspiradora, onde cada
              ambiente foi cuidadosamente planejado para promover o melhor
              aprendizado.
            </p>
          </Col>
        </Row>
        <Row>
        <Col css={pdR} lg={4} md={4} sm={12}>
            <a
             data-aos-offset='300'
             data-aos="flip-left"
             data-aos-easing='ease-in-out'
             href="/" className='container'
            >
              <img src={estrutura1} alt="Avatar" className='image'/>
              <div className='middle'>
                <span className='text'> Centro, Medianeira <img className='arrowup' src={ArrowUp} alt='' /></span>
              </div>
            </a>
          </Col>
          <Col css={pdR} lg={4} md={4} sm={12}>
            <a
            data-aos-offset='300'
            data-aos="flip-up"
            data-aos-easing='ease-in-out'
            href="/" className='container'
            >
              <img src={estrutura2} alt="Avatar" className='image'/>
              <div className='middle'>
                <span className='text'> Jd. Encantado, Medianeira <img className='arrowup' src={ArrowUp} alt='' /></span>
              </div>
            </a>
          </Col>
          <Col css={pdR} lg={4} md={4} sm={12}>
            <a 
             data-aos-offset='300'
             data-aos='flip-right'
             data-aos-easing='ease-in-out'
             href="/" className='container'
            >
              <img src={estrutura3} alt="Avatar" className='image'/>
              <div className='middle'>
                <span className='text'> Boulevard, Foz do Iguaçu <img className='arrowup' src={ArrowUp} alt='' /></span>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default EstruturaHome
