/** @jsx jsx */
import { css } from '@emotion/react'
import ImgPreVestibular from '../images/lp/pre-vestibular.png'
import ImgPreVestibularHover from '../images/lp/pre-vestibular-hover.png'
import ImgFundamental from '../images/lp/fundamental.png'
import ImgFundamentalHover from '../images/lp/fundamental-hover.png'
import ImgMedio from '../images/lp/medio.png'
import ImgMedioHover from '../images/lp/medio-hover.png'


export const EstruturaHomeContent = css`
  padding: 60px 0px;
  margin-top: 80px;
  font-family: "normalidad-variable", sans-serif;
  background-color: #F9F9F9;
  overflow: hidden;
  @media (min-width: 320px) and (max-width: 767px) {
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
    .arrowup{
      margin-bottom: 0px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }

   @media (min-width: 768px) and (max-width: 990px) {
    img {
      max-width: 100%;
      width: auto;
      margin-bottom: 22px;
    }
  }
  @media (max-width: 500px){
    margin-top: 0px;
    padding-top: 0px;
  }
  /*@media (min-width: 991px) and (max-width: 1199px) {
  }

  @media (min-width: 1200px) and (max-width: 3600px) {
  } */
`

export const headerContent = css`
  font-family: "normalidad-variable", sans-serif;
  h2 {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    margin-bottom: 20px;
    font-variation-settings: "wght" 130, "wght" 600;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #9D9C9B;
    padding-bottom: 32px;
    text-align: center;
    width: 100%;
    
  }
`

export const cardPreVestibular = css`
  background: url(${ImgPreVestibular}) #4BE66A no-repeat center center / cover;
  border-radius: 8px;
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 34px;
  transition: ease-in;
  margin-bottom: 8px;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 700;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #312E2D;
    width: 100%;
    text-align: left;
    max-width: 245px;
    margin-bottom: 20px;
  }
  a {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #312E2D;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #00854E;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
    }
  }
  :hover {
    background: url(${ImgPreVestibularHover}) #003770 no-repeat center center / cover;
  }
`

export const cardFundamental = css`
  background: url(${ImgFundamental}) #4BE66A no-repeat center center / cover;
  border-radius: 8px;
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 34px;
  transition: ease-in;
  height: 309px;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 700;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #312E2D;
    width: 100%;
    text-align: left;
    max-width: 245px;
    margin-bottom: 20px;
  }
  a {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #312E2D;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #00854E;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
    }
  }
  :hover {
    background: url(${ImgFundamentalHover}) #003770 no-repeat center center / cover;
  }
`

export const cardMedio = css`
  background: url(${ImgMedio}) #4BE66A;
  border-radius: 8px;
  padding-top: 34px;
  padding-left: 40px;
  padding-bottom: 34px;
  transition: ease-in;
  height: 100%;
  h4 {
    font-weight: 700;
    font-size: 24px;
    line-height: 120%;
    display: flex;
    align-items: center;
    color: #312E2D;
    font-variation-settings: "wght" 130, "wght" 700;
    margin-top: 315px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #312E2D;
    width: 100%;
    text-align: left;
    max-width: 245px;
    margin-bottom: 20px;
  }
  a {
    font-variation-settings: "wght" 130, "wght" 700;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    color: #312E2D;
    display: flex;
    align-items: flex-start;
    text-decoration: none;
    img {
      margin-left: 8px;
    }
    :hover {
      color: #00854E;
      text-decoration: none;
    }
    :active {
      color: #F7FF53;
    }
  }
  :hover {
    background: url(${ImgMedioHover});
  }
  @media (min-width: 320px) and (max-width: 767px) {
   margin-top: 8px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    margin-top: 8px;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    margin-top: 8px;
  }
`

export const btnSaibaMais = css`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #312E2D;
  font-variation-settings: "wght" 700;
  span {
    margin-right: 8px;
  }
  :hover {
    color: #ffffff;
    text-decoration: none;
  }
  :active {
    background: #F7FF53;
    color: #312E2D;
    text-decoration: none;
  }
`

export const pdL = css`
  padding-left: 4px !important;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-left: 15px !important;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    padding-left: 15px !important;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-left: 15px !important;
  }
`

export const pdR = css`
  padding-right: 4px !important;
  @media (min-width: 320px) and (max-width: 767px) {
    padding-right: 15px !important;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    padding-right: 15px !important;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    padding-right: 15px !important;
  }
  .container {
    position: relative;
    width: 100%;
    text-decoration: none;  
    display: inline-block;
  }

  .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    transition: 0.5s ease;
    backface-visibility: hidden;
    -webkit-filter: brightness(0.6);
    filter: brightness(0.6);
  }

  .middle {
    transition: 2s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 330px;
  }

  .container:hover .image {
    -webkit-filter: brightness(1);
    filter: brightness(1);
  }

  .container:hover .middle {
    opacity: 0;
  }
  .text {
    color: white;
    font-weight: 700;
    line-height: 120%;
    font-size: 22px;
    text-decoration: none; 
    @media (max-width:500px){
      font-size: 20px;
    }
  }
  .container:active .text, img{
    color: #00854E ;
    opacity: 1;
    img{
      filter: brightness(0) saturate(100%) invert(29%) sepia(85%) saturate(1536%) hue-rotate(135deg) brightness(90%) contrast(101%);
    }
  }
`